import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material'
import { DefaultTFuncReturn } from 'i18next'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React from 'react'

import { AmountBtnGroup } from '@/components/AmountBtnGroup/AmountBtnGroup'

import { BASE_URL } from '@/constants/env'

import { useAlertContext, useAuthContext } from '@/context'

import { Maybe, useRemoveProductFromCartMutation, useUpdateProductQuantityMutation } from '@/graphql/generated'
import { GET_ALL_PRODUCT_FROM_CART } from '@/graphql/queries'

import { useLaptop } from '@/hooks'

import { RedRemoveIcon } from '@/icons'

import { useCartStore, useIsDisabledProductRowStore } from '@/store'
import { productDataProps } from '@/store/cart'

import { getAmount } from '@/utils'

import * as S from './CartProductRow.styled'

interface Props {
  disabled?: boolean
  imgSrc?: Maybe<string>
  name: string | DefaultTFuncReturn | undefined
  description: string | DefaultTFuncReturn | null | undefined
  pricePerUnit: number
  currency: Maybe<string>
  id: string
  idForRemoveProductIfIsAuth?: string
  quantity?: string
}

export const CartProductRow = ({
  disabled = false,
  imgSrc,
  name = '',
  description = '',
  pricePerUnit,
  currency,
  id,
  quantity = '1',
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isOverLaptop } = useLaptop()
  const { isAuth } = useAuthContext()
  const { onOpenAlert } = useAlertContext()

  const [removeId] = useIsDisabledProductRowStore(state => [state.removeId])

  const [productData, removeLocalCartItemById, increase, decrease] = useCartStore(state => [
    state.productData,
    state.removeLocalCartItemById,
    state.increase,
    state.decrease,
  ])

  const targetObj = productData.find(prod => prod.skuId === id)
  const count = (targetObj as productDataProps)?.count

  const [removeProductFromCartMutation] = useRemoveProductFromCartMutation()
  const [updateProductQuantityMutation] = useUpdateProductQuantityMutation()
  // const { refetch: ss } = useGetSkusByIdsQuery({});

  const handleRemove = async (id: string) => {
    removeLocalCartItemById(id)

    if (isAuth) {
      await removeProductFromCartMutation({
        variables: { skuId: id },
        refetchQueries: [GET_ALL_PRODUCT_FROM_CART],
        onCompleted: () =>
          onOpenAlert({
            subTitle: t`app.product_has_been_removed_from_you_cart`,
          }),
      })
    }
    removeId(id)
  }

  const handleCount = (e: 'add' | 'subtract'): void => {
    if (!isAuth) {
      if (e === 'add') {
        increase(id)
      } else {
        if ((targetObj as productDataProps).count === 1) return
        decrease(id)
      }
    } else {
      updateProductQuantityMutation({
        variables: {
          skuId: id,
          input: {
            quantity: calculateQuantity(e, +quantity),
          },
        },
      })
    }
  }

  const calculateQuantity = (e: 'add' | 'subtract', quantity: number): number => {
    let res = quantity
    if (e === 'add') {
      res += 1
    } else {
      if (res === 1) return 1
      res -= 1
    }
    return res
  }

  return (
    <S.ProductRow disabled={disabled}>
      <S.ProductCell>
        <S.ProductImageBlock>
          {imgSrc && (
            <Image
              alt=""
              src={`${BASE_URL}/uploads/images/products/${imgSrc}`}
              width={1}
              height={1}
              priority={true}
              unoptimized={true}
            />
          )}
        </S.ProductImageBlock>
        <S.DescriptionBlock>
          <Typography variant="subtitle1">{name}</Typography>
          <S.DescriptionTypography color={theme.palette.ui.grey[600]} variant="body2">
            {description}
          </S.DescriptionTypography>
          {!isOverLaptop && (
            <Box mt="6px">
              <AmountBtnGroup setCount={handleCount} count={count} quantity={quantity} />
            </Box>
          )}
        </S.DescriptionBlock>
      </S.ProductCell>
      {!isOverLaptop && <Divider sx={{ width: '100%' }} />}
      {isOverLaptop ? (
        <>
          <Box>
            <Typography variant="body1">
              {Number(pricePerUnit).toFixed(2)}{' '}
              <Typography variant="body1" sx={{ fontWeight: 300 }} component="span">
                {currency}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <AmountBtnGroup setCount={handleCount} count={count} quantity={quantity} />
          </Box>
          <S.AmountWrap>
            <Typography variant="body1" sx={{ minWidth: '200px', textAlign: 'end' }}>
              {getAmount(pricePerUnit, isAuth ? quantity : count)}{' '}
              <Typography variant="body1" sx={{ fontWeight: 300 }} component="span">
                {currency}
              </Typography>
            </Typography>
            <Divider orientation="vertical" sx={{ height: '30px' }} />
            <IconButton sx={{ marginInlineStart: '-12px' }} size="large" onClick={() => handleRemove(id)}>
              <RedRemoveIcon />
            </IconButton>
          </S.AmountWrap>
        </>
      ) : (
        <S.MobileFooterCardWrapper>
          <S.MobileFooterCardBlock>
            <Typography variant="caption" color={theme.palette.ui.grey[600]}>
              {t('app.cart.price_per_unit')}
            </Typography>
            <Typography variant="body2">
              {pricePerUnit.toFixed(2)}{' '}
              <Typography variant="body2" sx={{ fontWeight: 300 }} component="span">
                {currency}
              </Typography>
            </Typography>
          </S.MobileFooterCardBlock>
          <S.MobileFooterCardBlock textAlign="end">
            <Typography variant="caption" color={theme.palette.ui.grey[600]}>
              {t('app.cart.amount')}
            </Typography>
            <Typography variant="body2">
              {getAmount(pricePerUnit, isAuth ? quantity : count)}{' '}
              <Typography variant="body2" sx={{ fontWeight: 300 }} component="span">
                {currency}
              </Typography>
            </Typography>
          </S.MobileFooterCardBlock>
          <IconButton
            onClick={() => handleRemove(id)}
            sx={{ position: 'absolute', insetInlineEnd: '8px', top: '8px' }}
            size="small"
          >
            <RedRemoveIcon />
          </IconButton>
        </S.MobileFooterCardWrapper>
      )}
    </S.ProductRow>
  )
}
