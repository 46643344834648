import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { useAlertContext, useAuthContext } from '@/context'

import { SkuEntity, useAddProductToCartMutation } from '@/graphql/generated'
import { GET_ALL_PRODUCT_FROM_CART } from '@/graphql/queries'

import { useCartStore } from '@/store'

import { ErrorMessage } from '@/types/baseTypes'

import { getErrorName } from '@/utils'

export const useAddToCart = (product: any) => {
  const router = useRouter()
  const [skuId, setSkuId] = useState('')
  const { onOpenAlert } = useAlertContext()
  const { t } = useTranslation()
  const { isAuth } = useAuthContext()
  const [addProductToCartMutation] = useAddProductToCartMutation()
  const [addToCart] = useCartStore(state => [state?.addToCart])

  const doAddToCart = async () => {
    const id = router.query.id
    if (!skuId || !id) {
      return onOpenAlert({
        subTitle: t`app.please_select_a_product`,
        error: true,
      })
    }
    // * DO WITH AUTHENTICATION USER
    if (isAuth) {
      await addProductToCartMutation({
        variables: {
          input: {
            quantity: 1,
            skuId: +skuId,
          },
        },
        refetchQueries: [GET_ALL_PRODUCT_FROM_CART],
        onError: error => {
          onOpenAlert({
            subTitle: getErrorName((error?.graphQLErrors[0]?.extensions?.exception as ErrorMessage)?.name),
            error: true,
          })
        },
        onCompleted: () => onOpenAlert({ subTitle: t`app.cart.product_added_to_cart` }),
      })
    }

    addToCart(id as string, skuId, product?.skus as SkuEntity[], product?.baseCurrency.name as string)
    if (!isAuth) {
      onOpenAlert({ subTitle: t`app.cart.product_added_to_cart` })
    }
  }
  return { doAddToCart, setSkuId, skuId }
}
