import { useTranslation } from 'next-i18next'
import React from 'react'

import { Alert } from '@/components'

import { PATHS } from '@/constants/routes'

import { usePhoneVerify } from '@/hooks'

import * as S from './AlertDefault.styled'

interface Props {
  isNeedAlert?: boolean
  phoneNumber?: string | null
}

// PHASE_2: Create global toast component

export const AlertDefaultCart = ({ isNeedAlert, phoneNumber }: Props) => {
  const { t } = useTranslation()
  const { phoneVerify } = usePhoneVerify()

  if (!isNeedAlert) return null
  return (
    <Alert type="error" mb="20px">
      <span>
        {t`app.cart.limits_exceeded`}. {t`app.cart.please`}&nbsp;
        <S.AlertLink href={!phoneNumber ? PATHS.profile : ''} onClick={() => phoneVerify()}>{t`verify`}</S.AlertLink>
      </span>
    </Alert>
  )
}
