import { CartProductSchema } from '@/graphql/generated'

export const transformDataToTotal = (data: CartProductSchema[]) =>
  data.map(obj => {
    return {
      productId: obj.product.id,
      skuId: obj.skuId,
      count: obj.quantity,
      value: obj.total,
      baseCurrency: obj.product.baseCurrency.name,
    }
  })
