import { Box, Button, Typography, useTheme } from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google'
import { Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import * as S from '@/components/Form/SignInForm.styled'
import { Alert, CircularProgress, OrDivider } from '@/components'
import { CheckboxFormik } from '@/components/uiKit'
import { TextFieldFormik } from '@/components/uiKit/TextFieldFormik/TextFieldFormik'

import { MODAL_NAME } from '@/constants/modalName'
import { AUTH_PATHS, PATHS } from '@/constants/routes'

import { useAuthContext, useCartContext, useModalContext } from '@/context'
import { useSiteIdContext } from '@/context/SiteIdContext'

import { useSignInByGoogleMutation, useSignInMutation } from '@/graphql/generated'

import { useLaptop } from '@/hooks'
import { useCaptchaV3 } from '@/hooks/useCaptchaV3'
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage'

import { ArrowBackIcon, ArrowIcon, GoogleIcon } from '@/icons'

import { getErrorName } from '@/utils'
import { setAuthToken } from '@/utils/authToken'
import { getMyLocationInfo } from '@/utils/getMyLocationInfo'

import { signInSchema } from '@/validation/signIn.schema'

import { FormHeader } from '../FormHeader/FormHeader'

export const SignInForm = () => {
  const { isOverLaptop } = useLaptop()
  const { openModal, onCloseModal, onOpenModal } = useModalContext()
  const { onAuth } = useAuthContext()
  const { isProductInTheCart } = useCartContext()
  const { siteId } = useSiteIdContext()
  const captcha = useCaptchaV3()

  const [signInByGoogleMutation, { error: googleError }] = useSignInByGoogleMutation()
  const [signIn, { data, error }] = useSignInMutation()

  const router = useRouter()

  const theme = useTheme()

  const { t } = useTranslation()
  const { isENLang } = useCurrentLanguage()

  const [isSubmitting, setSubmitting] = useState(false)

  const handleSubmit = async (values: any) => {
    if (isSubmitting || data) return
    setSubmitting(true)

    await signIn({
      variables: {
        input: {
          email: values.email,
          password: values.password,
          captcha: await captcha.getToken(),
        },
      },
      onCompleted: data => {
        setSubmitting(false)

        setAuthToken(data.signIn.accessToken, values.rememberMe ? window.localStorage : window.sessionStorage)

        // если модлка закрыта тогда возвращаемся на хом
        if (!openModal.open) {
          if (router.query.from) {
            router.push(router.query.from as string)
          } else {
            router.push(PATHS.home)
          }
        }
        onAuth()
        onCloseModal()
      },
      onError: () => setSubmitting(false),
    })
  }

  const handleNavigateToSignUpPage = () => {
    if (openModal) {
      onCloseModal()
    }
    // * if mobile and cart is empty
    if (!isOverLaptop || !isProductInTheCart) {
      router.push(
        {
          pathname: AUTH_PATHS.signUp,
          query: { from: AUTH_PATHS.signIn },
        },
        AUTH_PATHS.signUp,
      )
    } else {
      onOpenModal(MODAL_NAME.signUp)
    }
  }

  const handleNavigateToForgotPasswordPage = () => {
    // * if mobile and cart is empty
    if (!isOverLaptop || !isProductInTheCart) {
      router.push(AUTH_PATHS.forgotPassword)
    } else {
      onOpenModal(MODAL_NAME.forgotPassword)
    }
  }

  const { userCountry } = getMyLocationInfo()

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      signInByGoogleMutation({
        variables: {
          input: {
            accessToken: tokenResponse.access_token,
            country: userCountry,
            siteId: siteId,
          },
        },
        onCompleted: data => {
          setAuthToken(data.signInByGoogle.accessToken)
          onAuth()

          if (!openModal.open) {
            router.push(PATHS.home)
          }

          onCloseModal()
        },
      })
    },
  })

  const handleGoogleClick = () => {
    login()
  }

  const goBack = () => {
    if (router.query?.from) {
      router.back()
    } else {
      router.push(PATHS.home)
    }
  }

  return (
    <S.FormWrapper>
      {isOverLaptop && !openModal?.open && (
        <Button
          sx={{ marginBottom: '40px' }}
          startIcon={<ArrowBackIcon />}
          variant="back"
          size="medium"
          onClick={goBack}
        >
          {t('app.back')}
        </Button>
      )}
      <FormHeader title={t('app.sign_in')} subTitle={t('app.auth.welcome_back')} />
      <S.Google startIcon={<GoogleIcon />} variant="outlined" fullWidth onClick={handleGoogleClick}>
        {t('app.auth.log_in_google')}
      </S.Google>
      <OrDivider />
      <Formik
        initialValues={{
          email: '',
          password: '',
          rememberMe: false,
          captcha: '',
        }}
        validationSchema={signInSchema(isENLang)}
        onSubmit={handleSubmit}
      >
        {() => {
          return (
            <Form>
              <TextFieldFormik
                name="email"
                label={t('app.auth.email_address')}
                placeholder={t('app.auth.ey_email_address')}
              />
              <Box sx={{ position: 'relative' }}>
                <TextFieldFormik
                  name="password"
                  label={t('app.auth.password')}
                  placeholder={t('app.auth.ey_password')}
                  type="password"
                />
                <S.ForgotPasswordTypography onClick={handleNavigateToForgotPasswordPage}>
                  {t('app.auth.forgot_password')} ?
                </S.ForgotPasswordTypography>
              </Box>
              <CheckboxFormik name="rememberMe" label="Remember me" sx={{ paddingTop: '8px' }} />

              {captcha.renderContainer()}

              <S.ButtonFW
                type="submit"
                endIcon={
                  isSubmitting ? <CircularProgress loading={true} color={theme.palette.base.white} /> : <ArrowIcon />
                }
                size="medium"
              >
                {t('app.sign_in')}
              </S.ButtonFW>
            </Form>
          )
        }}
      </Formik>

      {error || googleError ? (
        <Alert type="error" mt="20px">
          {error ? getErrorName(error?.message) : getErrorName('google-error')}
        </Alert>
      ) : null}

      <Typography mt="24px" variant="body2">
        {t('app.auth.not_a_account')}?{' '}
        <S.LinkTypography as="span" color={theme.palette.ui.primary.main} onClick={handleNavigateToSignUpPage}>
          {t('app.sign_up')}
        </S.LinkTypography>
      </Typography>
    </S.FormWrapper>
  )
}
