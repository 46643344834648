import { styled } from '@mui/material'
import Link, { LinkProps } from 'next/link'

export const AlertLink = styled(Link)<LinkProps>(() => ({
  color: '#F2405D',
  '&:active': {
    color: '#F2405D',
  },
  '&:visited': {
    color: '#F2405D !important',
  },
  fontWeight: 400,
}))
