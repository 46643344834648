import { Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import * as S from '@/components/Form/SignInForm.styled'
import { Alert, CircularProgress } from '@/components'
import { TextFieldFormik } from '@/components/uiKit'

import { MODAL_NAME } from '@/constants/modalName'
import { AUTH_PATHS } from '@/constants/routes'

import { useCartContext, useModalContext } from '@/context'

import { useResetPasswordMutation } from '@/graphql/generated'

import { useLaptop } from '@/hooks'
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage'

import { ArrowIcon } from '@/icons'

import { useAuthVariablesStore } from '@/store'

import { getErrorName } from '@/utils'

import { forgotPasswordSchema } from '@/validation'

import { FormHeader } from '../FormHeader/FormHeader'

export const ForgotPasswordForm = () => {
  const [error, setError] = useState(false)
  const router = useRouter()
  const { isOverLaptop } = useLaptop()

  const { onOpenModal } = useModalContext()
  const { isProductInTheCart } = useCartContext()
  const { t } = useTranslation()
  const { isENLang } = useCurrentLanguage()

  const [setEmail] = useAuthVariablesStore(state => [state.setEmail])

  const [resetPasswordMutation, { data, loading, error: err }] = useResetPasswordMutation()

  const handleSubmit = async (values: { email: string }) => {
    if (data || loading) return
    setError(false)
    await resetPasswordMutation({
      variables: {
        input: {
          email: values.email,
        },
      },
      onCompleted: data => {
        setEmail(values.email)
        if (!data.resetPassword) {
          setError(true)
        } else {
          // * if mobile and cart is empty
          // PHASE_2: merge mobile and laptop logic
          if (!isOverLaptop || !isProductInTheCart) {
            router.push(AUTH_PATHS.confirmPassword)
          } else {
            onOpenModal(MODAL_NAME.confirmPasswordForm)
          }
        }
      },
    })
  }

  return (
    <S.FormWrapper>
      <FormHeader title={t('app.auth.forgot_password')} subTitle={t('app.auth.pe_your_email_address')} />
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={forgotPasswordSchema(isENLang)}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextFieldFormik
            name="email"
            label={t('app.auth.email_address')}
            placeholder={t('app.auth.ey_email_address')}
          />
          <S.ButtonFW
            type="submit"
            endIcon={loading ? <CircularProgress loading={true} color="#fff" /> : <ArrowIcon />}
            size="medium"
          >
            {t('app.send')}
          </S.ButtonFW>
        </Form>
      </Formik>
      {error || err ? (
        <Alert type="error" mt="20px">
          {error ? getErrorName('notUserOrNotVerify') : getErrorName(err?.graphQLErrors[0].message || '')}
        </Alert>
      ) : null}
    </S.FormWrapper>
  )
}
