import { gql } from '@apollo/client'

export const GET_PRODUCTS = gql`
  query GetProducts($input: GetProductsInput!) {
    getProducts(input: $input) {
      products {
        id
        nameEN
        nameAR
        siteId
        shortDescriptionEN
        shortDescriptionAR
        fullDescriptionEN
        fullDescriptionAR
        instructionEN
        instructionAR
        termsEN
        termsAR
        image
        countries
        baseCurrencyId
        categoryId
        baseCurrency {
          id
          name
        }
        category {
          id
        }
        preset {
          isPreset
          presetName
        }
      }
      count
    }
  }
`
