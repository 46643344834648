import { Fade, CircularProgress as MuiCircularProgress } from '@mui/material'
import React from 'react'

import { palette } from '@/theme/palette'

interface Props {
  loading: boolean
  color?: string
  size?: string
}

export const CircularProgress = ({ loading, color = palette.ui.grey[600], size = '24px' }: Props) => {
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '800ms' : '0ms',
        width: size,
        height: size,
      }}
      unmountOnExit
    >
      <MuiCircularProgress sx={{ color: color }} />
    </Fade>
  )
}
