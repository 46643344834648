import { Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import React, { useEffect } from 'react'

import { useIntlContext } from '@/context/IntlContext'

import { CartProductSchema } from '@/graphql/generated'

import { useCartStore } from '@/store'

import { CurrencyType } from '@/types/baseTypes'

import { getResultTotalCart, transformDataToTotal } from '@/utils'

import * as S from './TotalValueToCart.styled'

interface Props {
  currency: string
  exchangeRatesData: any
  isAuth: boolean
  productData?: CartProductSchema[]
  // PHASE_2: remove all any
  cb?: any
}

export const TotalValueToCart = ({ currency, exchangeRatesData, isAuth, productData, cb }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [productDataStore] = useCartStore(state => [state.productData])
  const { currentCurrency } = useIntlContext()

  const total = getResultTotalCart(
    isAuth ? transformDataToTotal(productData as CartProductSchema[]) : productDataStore,
    currentCurrency as CurrencyType,
    exchangeRatesData,
    isAuth,
  )

  useEffect(() => {
    cb(total)
  }, [total])

  return (
    <S.TotalValueToCart>
      <Typography variant="body2" color={theme.palette.ui.grey[600]}>
        {t('app.cart.total')}
      </Typography>
      <S.ValueBlock>
        {total}{' '}
        <Typography component="span" variant="h6">
          {currency}
        </Typography>
      </S.ValueBlock>
    </S.TotalValueToCart>
  )
}
