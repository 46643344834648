import { CircularProgress } from '@mui/material'
import React from 'react'

import * as S from './LoaderWrapper.styles'

type Props = {
  loading?: boolean
  isDisableCirculate?: boolean
  children: React.ReactNode
}

export const LoaderWrapper = ({ loading = false, children, isDisableCirculate }: Props) => {
  return loading ? <S.Center>{!isDisableCirculate ? <CircularProgress size="48px" /> : ''}</S.Center> : <>{children}</>
}
