import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useRef, useState } from 'react'
import AuthCode, { AuthCodeRef } from 'react-auth-code-input'

import { Alert, CircularProgress } from '@/components'

import { MODAL_NAME } from '@/constants/modalName'
import { AUTH_PATHS } from '@/constants/routes'

import { useCartContext, useModalContext } from '@/context'

import { useConfirmRegistrationMutation } from '@/graphql/generated'

import { useLaptop } from '@/hooks'

import { ArrowIcon } from '@/icons'

import { useAuthVariablesStore } from '@/store'

import { getErrorName } from '@/utils'

import * as S from '../SignInForm.styled'
import { FormConfirmHeader } from '../FormConfirmHeader/FormConfirmHeader'

export const ConfirmEmailForm = () => {
  const { isOverLaptop } = useLaptop()
  const [error, setError] = useState(false)
  const [code, setCode] = useState('')
  const ref = useRef<AuthCodeRef>(null)

  const [email, clearEmail] = useAuthVariablesStore(state => [state.email, state.clearEmail])

  const { onOpenModal } = useModalContext()
  const { isProductInTheCart } = useCartContext()

  const router = useRouter()
  const { t } = useTranslation()
  const isDisabledBtn = code.length < 4

  const [confirmRegistrationMutation, { data, loading }] = useConfirmRegistrationMutation()

  const handleSubmit = async () => {
    if (loading || data?.confirmRegistration) return

    await confirmRegistrationMutation({
      variables: {
        input: { email, code },
      },
      onCompleted: data => {
        if (!data.confirmRegistration) {
          setError(true)
          ;(ref.current as AuthCodeRef | null)?.clear()
        } else {
          clearEmail()
          // * if mobile and cart is empty
          if (!isOverLaptop || !isProductInTheCart) {
            router.push(AUTH_PATHS.success)
          } else {
            onOpenModal(MODAL_NAME.success)
          }
        }
      },
      onError: () => (ref.current as AuthCodeRef | null)?.clear(),
    })
  }

  return (
    <S.FormWrapper>
      <FormConfirmHeader title={t('app.auth.confirmation_email')} subTitle={t('app.auth.please_enter_email_address')} />
      <S.AuthCodeWrapper>
        <AuthCode
          onChange={res => setCode(res)}
          length={4}
          containerClassName="containerClass"
          inputClassName="inputClass"
          ref={ref}
        />
        <S.ButtonFW
          disabled={isDisabledBtn}
          onClick={handleSubmit}
          endIcon={loading ? <CircularProgress loading={true} color="#fff" /> : <ArrowIcon />}
          size="medium"
        >
          {t('app.profilePage.confirm')}
        </S.ButtonFW>
      </S.AuthCodeWrapper>
      {error && (
        <Alert type="error" mt="20px">
          {getErrorName('notConfirmEmail')}
        </Alert>
      )}
    </S.FormWrapper>
  )
}
