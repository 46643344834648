import React from 'react'

import { useAuthContext } from '@/context'

import { MinusIcon, PlusIcon } from '@/icons'

import * as S from './AmountBtnGroup.styled'

interface Props {
  setCount: (x: 'add' | 'subtract') => void
  count: string | number
  quantity?: string
}

export const AmountBtnGroup = ({ setCount, count, quantity }: Props) => {
  const { isAuth } = useAuthContext()

  return (
    <S.AmountBtnGroup>
      <S.AmountBtn onClick={() => setCount('subtract')}>
        <MinusIcon />
      </S.AmountBtn>
      <S.AmountCountField>{isAuth ? quantity : count}</S.AmountCountField>
      <S.AmountBtn onClick={() => setCount('add')}>
        <PlusIcon />
      </S.AmountBtn>
    </S.AmountBtnGroup>
  )
}
