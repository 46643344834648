import { productDataProps } from '@/store/cart'

export const dataToFetchCreateCart = (productData: productDataProps[]) => {
  return productData.map(item => {
    return {
      productId: item.productId,
      count: item.count,
      skuId: item.skuId,
      value: item.value,
    }
  })
}
