import { Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { Alert, CircularProgress } from '@/components'
import { ButtonFW, FormWrapper } from '@/components/Form/SignInForm.styled'
import { TextFieldFormik } from '@/components/uiKit'

import { MODAL_NAME } from '@/constants/modalName'
import { AUTH_PATHS } from '@/constants/routes'

import { useCartContext, useModalContext } from '@/context'

import { useChangePasswordMutation } from '@/graphql/generated'

import { useLaptop } from '@/hooks'
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage'

import { ArrowIcon } from '@/icons'

import { useAuthVariablesStore } from '@/store'

import { getErrorName } from '@/utils'

import { newPasswordSchema } from '@/validation/newPassword.schema'

import { FormHeader } from '../FormHeader/FormHeader'

export const NewPasswordForm = () => {
  const router = useRouter()
  const { isOverLaptop } = useLaptop()

  const { onOpenModal } = useModalContext()
  const { isProductInTheCart } = useCartContext()
  const { t } = useTranslation()
  const { isENLang } = useCurrentLanguage()

  const [email, code] = useAuthVariablesStore(state => [state.email, state.code])

  const [changePasswordMutation, { data, error, loading }] = useChangePasswordMutation()

  const handleSubmit = async (values: Record<string, string>) => {
    if (loading || !!data) return
    await changePasswordMutation({
      variables: {
        input: {
          email: email,
          code: code,
          password: values.password,
        },
      },
      onCompleted: () => {
        // * if mobile and cart is empty
        if (!isOverLaptop || !isProductInTheCart) {
          router.push(AUTH_PATHS.successPassword)
        } else {
          onOpenModal(MODAL_NAME.successPassword)
        }
      },
    })
  }

  useEffect(() => {
    if (!email && !code) {
      router.push(AUTH_PATHS.forgotPassword)
    } else if (email && !code) {
      router.push(AUTH_PATHS.confirmPassword)
    }
  }, [])

  return (
    <FormWrapper>
      <FormHeader title={t('app.auth.set_new_password')} subTitle={t('app.auth.please_set_new_password')} />
      <Formik
        initialValues={{
          password: '',
          repeatPassword: '',
        }}
        validationSchema={newPasswordSchema(isENLang)}
        onSubmit={handleSubmit}
      >
        <Form>
          <TextFieldFormik
            name="password"
            label={t('app.auth.password')}
            placeholder={t('app.auth.ey_password')}
            type="password"
          />
          <TextFieldFormik
            name="repeatPassword"
            label={t('app.auth.repeat_password')}
            placeholder={t('app.auth.repeat_new_password')}
            type="password"
          />
          <ButtonFW
            type="submit"
            endIcon={loading ? <CircularProgress loading={true} color="#fff" /> : <ArrowIcon />}
            size="medium"
          >
            {t('app.apply')}
          </ButtonFW>
        </Form>
      </Formik>
      {error ? (
        <Alert type="error" mt="20px">
          {getErrorName('default')}
        </Alert>
      ) : null}
    </FormWrapper>
  )
}
