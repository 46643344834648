import { Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { TitleRow } from './RowForCartTableHead.styled'

export const RowForCartTableHead = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <TitleRow>
      <Typography variant="body2" color={theme.palette.ui.grey[600]}>
        {t('app.cart.product')}
      </Typography>
      <Typography variant="body2" color={theme.palette.ui.grey[600]}>
        {t('app.cart.price_per_unit')}
      </Typography>
      <Typography variant="body2" color={theme.palette.ui.grey[600]}>
        {t('app.cart.quantity')}
      </Typography>
      <Typography variant="body2" color={theme.palette.ui.grey[600]}>
        {t('app.cart.amount')}
      </Typography>
    </TitleRow>
  )
}
