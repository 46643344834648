import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'

import { PATHS } from '@/constants/routes'

import { useErrorsStore } from '@/graphql/client'
import { useCreateCartMutation } from '@/graphql/generated'
import { GET_ALL_PRODUCT_FROM_CART } from '@/graphql/queries'

import { useCartStore } from '@/store'

import { dataToFetchCreateCart } from '@/utils'
import { hasAuthToken, removeAuthToken } from '@/utils/authToken'

interface Props {
  children: React.ReactNode
}

export const AuthContext = React.createContext({
  isAuth: false,
  onAuth: () => {},
  onLogout: () => {},
})

export const useAuthContext = () => {
  const authContext = useContext(AuthContext)

  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }

  return authContext
}

export const AuthProvider = ({ children }: Props) => {
  const router = useRouter()
  const [isAuth, setIsAuth] = useState(false)

  const [productData, clearCartLocalStorage] = useCartStore(state => [state.productData, state.clearCartLocalStorage])

  const error = useErrorsStore()
  const [createCartMutation] = useCreateCartMutation()

  const handleSuccessAuth = () => {
    setIsAuth(true)

    if (productData?.length) {
      createCartMutation({
        variables: {
          input: {
            state: {
              productData: dataToFetchCreateCart(productData),
            },
          },
        },
        refetchQueries: [GET_ALL_PRODUCT_FROM_CART],
      })
    }
  }

  const handleLogout = () => {
    removeAuthToken()
    setIsAuth(false)
    clearCartLocalStorage()
    router.push(PATHS.home)
  }

  useEffect(() => {
    if (error && error?.error?.extensions?.code === 'UNAUTHENTICATED') {
      handleLogout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, error?.error?.extensions?.code])

  useEffect(() => {
    if (isAuth) {
      return
    }

    setIsAuth(hasAuthToken())
  }, [isAuth])

  const value = {
    isAuth,
    onAuth: handleSuccessAuth,
    onLogout: handleLogout,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
