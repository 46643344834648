import { useEffect, useState } from 'react'

import { useAuthContext } from '@/context'
import { useSiteIdContext } from '@/context/SiteIdContext'

import {
  UserEntity,
  useConvertCurrencyMutation,
  useGetCurrentUserQuery,
  useGetKycLimitsQuery,
} from '@/graphql/generated'

import { getAllowableLimitOnPurchase } from '@/utils'

interface useNeedAlertInDefaultCartProps {
  cartDataLength?: number
  currentUserData?: UserEntity
  currencyId?: number
}

export const useNeedAlertInDefaultCart = ({
  cartDataLength,
  currentUserData,
  currencyId = 0,
}: useNeedAlertInDefaultCartProps) => {
  const { siteId } = useSiteIdContext()
  const { isAuth } = useAuthContext()
  const [total, setTotal] = useState(0)
  const [spent, setSpent] = useState(0)

  const phoneIsVerified = currentUserData?.phoneIsVerified
  const kycLevel = currentUserData?.kycLevel

  const { data: userData } = useGetCurrentUserQuery()

  const [convertCurrency, { data: convertCurrencyData, loading: convertCurrencyLoading }] = useConvertCurrencyMutation()

  const convertUserTotalToCurrentCurrency = (val?: number) => {
    if (!val) return 0
    if (!isFinite(val)) return 0
    return val
  }

  const { data: kycData, loading: kycLoading } = useGetKycLimitsQuery({
    variables: {
      getKycLimitsId: siteId.toString(),
    },
    skip: !isAuth,
  })

  const allowableLimitOnPurchase = getAllowableLimitOnPurchase(kycData?.getKycLimits, kycLevel)

  // TODO: find out what is that and replace with understandable logic
  const isNeedVerify =
    isAuth &&
    !convertCurrencyLoading &&
    !kycLoading &&
    !phoneIsVerified &&
    !!cartDataLength &&
    total &&
    convertCurrencyData &&
    +total > convertCurrencyData.convertCurrency - spent

  useEffect(() => {
    if (isFinite(allowableLimitOnPurchase)) {
      convertCurrency({
        variables: {
          input: {
            currencyFromId: 1, // USD
            currencyToId: currencyId,
            value: allowableLimitOnPurchase,
          },
        },
      })
    }
  }, [currencyId, allowableLimitOnPurchase])

  useEffect(() => {
    convertCurrency({
      variables: {
        input: {
          currencyFromId: 1, // USD
          currencyToId: currencyId,
          value: convertUserTotalToCurrentCurrency(Number(userData?.getCurrentUser.total)),
        },
      },
    }).then(({ data }: Record<string, any>) => {
      setSpent(data.convertCurrency)
    })
  }, [currencyId, userData?.getCurrentUser.total])

  return { total, setTotal, isNeedVerify: Boolean(isNeedVerify) }
}
